<template>
    <base-entity
        :config="{
            backRouteName: 'destinations.index',
            getEntity: getEntity,
            onSubmit: onSubmit,
            showControlButtons: true
        }"
    >
        <template v-slot:title="{ isUpdateMode }">
            {{ isUpdateMode ? 'Edit Destination' : 'Create Destination'}}
        </template>

        <template v-slot:form>
            <b-row>
                <b-col sm="4">
                    <base-input
                        v-model="entity.name"
                        name="name"
                        :rules="{ required: true }"
                        required
                        label="Name" size="sm">
                    </base-input>
                </b-col>
            </b-row>
        </template>
    </base-entity>
</template>
<script>
import BaseEntity from '@/components/Custom/BaseEntity';
import store from './store';
import {
    Select,
    Option
} from 'element-ui'

export default {
    data() {
        return {
            entity: {
                name: '',
                delivery_fee: ''
            }
        }
    },
    methods: {
        onSubmit(id) {
            if (id) {
                store.dispatch('update', this.entity).then(() => {
                    this.toastSuccess('DriDestinationver Updated');
                    this.$router.push({ name: 'destinations.index' });
                });
            } else {
                store.dispatch('store', this.entity).then(() => {
                    this.toastSuccess('Destination Created');
                    this.$router.push({ name: 'destinations.index' });
                });
            }
        },
        getEntity(id) {
            store.dispatch('show', id).then(({ data }) => {
                this.entity = data;
            });
        }
    },
    computed: {
        operations() {
            return store.getters.operations;
        }
    },
    components: {
        BaseEntity,
        [Select.name]: Select,
        [Option.name]: Option
    }
}
</script>