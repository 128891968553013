import DestinationRequest from '@requests/destination_request';

export default {
    getEntities({ commit }, filter) {
        DestinationRequest.index(filter)
            .then((data) => {
                commit('SET_ENTITIES', data);
            });
    },
    store(_, payload) {
        console.log(payload);
        return DestinationRequest.store(payload);
    },
    update(_, entity) {
        return DestinationRequest.update(entity.id, entity);
    },
    show(_, id) {
        return DestinationRequest.show(id);
    },
    destroy(_, entity) {
        return DestinationRequest.destroy(entity.id);
    }
}