<template>
  <div>
    <base-header class="pb-6" type="primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">
              <slot name="title" :isUpdateMode="isUpdateMode">{{ title }}</slot>
          </h6>
          <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav> -->
        </b-col>
      </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <slot name="form" :isUpdateMode="isUpdateMode">Please implement form</slot>
                    <template v-if="config.showControlButtons">
                      <hr>
                      <b-row>
                          <b-col sm="12" class="d-flex justify-content-end">
                              <base-button nativeType="button" @click="goBack()" type="secondary">Cancel</base-button>
                              <base-button nativeType="submit" type="primary">{{ isUpdateMode ? 'Save Changed' : 'Save' }}</base-button>
                          </b-col>
                      </b-row>
                    </template>
                </b-form>
            </validation-observer>
        </b-card-body>
      </b-card>
    </div>
    <slot name="others"></slot>
  </div>
</template>

<script>
  export default {
    methods: {
        goBack() {
            this.$router.replace({ name: this.config.backRouteName });
        },
        onSubmit() {
            if (this.config.onSubmit && typeof this.config.onSubmit === 'function') {
                this.config.onSubmit(this.isUpdateMode);
            }
        }
    },
    mounted() {
        if (this.isUpdateMode && this.config.getEntity && typeof this.config.getEntity === 'function') {
            this.config.getEntity(this.isUpdateMode);
        }
    },
    computed: {
        isUpdateMode() {
            return this.$route.params.id;
        }
    },
    props: {
        title: {
            type: String,
            required: false,
            default: 'Entity Title'
        },
        config: {
            type: Object,
            default: () => {
                return {
                    backRouteName: null
                }
            }
        }
    }
  }
</script>
