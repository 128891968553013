var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-entity',{attrs:{"config":{
        backRouteName: 'destinations.index',
        getEntity: _vm.getEntity,
        onSubmit: _vm.onSubmit,
        showControlButtons: true
    }},scopedSlots:_vm._u([{key:"title",fn:function(ref){
    var isUpdateMode = ref.isUpdateMode;
return [_vm._v(" "+_vm._s(isUpdateMode ? 'Edit Destination' : 'Create Destination')+" ")]}},{key:"form",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-input',{attrs:{"name":"name","rules":{ required: true },"required":"","label":"Name","size":"sm"},model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", $$v)},expression:"entity.name"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }